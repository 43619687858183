<template>
    <div class="contact">
        <ul class="contact__list">
            <li class="contact__item">
                <a class="contact__link" href="mailto:stanislav@cingel.sk">
                    <img class="contact__icon" src="../assets/at.svg" alt="">
                    stanislav@cingel.sk
                </a>
            </li>
            <li class="contact__item">
                <a class="contact__link" href="https://gitlab.com/cstanislav" target="_blank">
                    <img class="contact__icon" src="../assets/git-alt.svg" alt="">
                    gitlab.com/cstanislav
                </a>
            </li>
            <li class="contact__item">
                <a class="contact__link" href="https://www.linkedin.com/in/stanislavcingel" target="_blank">
                    <img class="contact__icon" src="../assets/linkedin.svg" alt="">
                    linkedin.com/in/stanislavcingel
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'Kontakt',
  props: {}
}
</script>

<style lang="scss">
    .contact {
        @include above($c-tertiary);
        border-radius: 0;
        border-bottom: 0;

        &__heading {
            font-weight: 700;
            font-size: 2.5rem;
            text-align: center;
            color: $c-white;
            margin-bottom: 4rem;
        }
        
        &__list {
            @include ul-unstyled;

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            // margin: 0 -1rem;
            min-height: 35vh;
            padding: 5rem;
        }

        &__item {
            padding: 0 1rem;
            margin-bottom: 1rem;
        }

        &__link {
            @include a-unstyled;
            @include above;

            display: flex;
            align-items: center;
            padding: 1.25rem 1.75rem;
            // border-radius: .35rem;
            font-size: $font-22px;
            // background-color: $c-white;
            // border-bottom: 2px solid darken($c-white, 10);

            &:hover {
                text-decoration: underline;
            }
        
            @media (max-width: 1100px) {
                font-size: $font-18px;
                padding: .75rem 1rem;
            }
        }

        &__icon {
            height: 55px;
            margin-right: 1rem;

            @media (max-width: 1100px) {
                height: 35px;
            }
        }
    }
</style>