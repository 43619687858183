<template>
  <Main />
</template>

<script>
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style lang="scss">
// Base
img {
  display: block;
}
* {
  box-sizing: border-box;
}

// Global
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
}

h1 {
  font-weight: 700;
  font-size: 32px;
  margin-top: 0;
  margin-bottom: .35em;
}

#app {
  font-family: $font-family;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
