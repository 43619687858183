<template>
  <div class="cv">
    <div class="cv__center">
      <div class="cv__container">
        <div class="cv__category">
          <div class="cv__content">
            <div class="cv__item">
              <div class="cv__head">
                <div class="cv__title">Senior Full Stack Developer</div>
                <div class="cv__date">1/2024 – <strong>súčasnosť</strong></div>
              </div>
              <div class="cv__institution">
                AT&T Global Network Services Czech Republic s.r.o.
              </div>
            </div>
            <div class="cv__item">
              <div class="cv__head">
                <div class="cv__title">Software Integration Engineer</div>
                <div class="cv__date">2/2023 – 12/2023</div>
              </div>
              <div class="cv__institution">
                Scheidt & Bachmann Slovensko s.r.o.
              </div>
              <div class="cv__description">
                Vývoj
                <a
                  href="https://www.scheidt-bachmann.de/en/fare-collection-systems/home"
                  target="_blank"
                  >systémov vybavenia cestujúcich</a
                >. Automatizácia testovania, správa distribuovaných služieb
                (Robot Framework, Kubernetes).
              </div>
            </div>
            <div class="cv__item">
              <div class="cv__head">
                <div class="cv__title">SZČO</div>
                <div class="cv__date">5/2017 – <strong>súčasnosť</strong></div>
              </div>
              <div class="cv__institution">
                Stanislav Cingel – Cingel Design
              </div>
            </div>
            <!-- <div class="cv__item">
              <div class="cv__head">
                <div class="cv__title">Full Stack Developer</div>
                <div class="cv__date">11/2022 – 12/2022</div>
              </div>
              <div class="cv__institution">PTT Software s.r.o.</div>
              <div class="cv__description">
                Vývoj nástroja na plánovanie dopravy
                <a
                  href="https://www.pttsoftware.eu/cs/produkty/system-pro-planovani-dopravy"
                  target="_blank"
                  >Magnus</a
                >
                (C#, WPF, XAML, LINQ).
              </div>
            </div> -->
            <div class="cv__item">
              <div class="cv__head">
                <div class="cv__title">Frontend Developer</div>
                <div class="cv__date">11/2021 – 9/2022</div>
              </div>
              <div class="cv__institution">TESCO SW a.s.</div>
              <div class="cv__description">
                Vývoj frameworku
                <a href="https://teaf.tescosw.cz/mw/design/" target="_blank"
                  >MultiWeb</a
                >
                (TypeScript, React, Redux, Less). Písanie automatických UI
                testov (Selenium, C#).
              </div>
            </div>
            <div class="cv__item">
              <div class="cv__head">
                <div class="cv__title">Web Developer</div>
                <div class="cv__date">11/2017 – 03/2021</div>
              </div>
              <div class="cv__institution">inQool a.s.</div>
              <div class="cv__description">
                Webové stránky miest, obcí, verejných inštitúcií PHP
                (Nette/Latte), SQL, CSS (Sass, BEM). Vývoj informačného systému
                <a href="https://grantys.cz/" target="_blank">Grantys</a>
                (AngularJS, React, Redux).
              </div>
            </div>
          </div>
        </div>
        <div class="cv__category">
          <div class="cv__heading">
            <span class="cv__logo">»</span>Vzdelanie
          </div>
          <div class="cv__content">
            <div class="cv__item">
              <div class="cv__head">
                <div class="cv__title">Aplikovaná informatika Bc.</div>
                <div class="cv__date">09/2017 – 07/2021</div>
              </div>
              <div class="cv__institution">
                Fakulta informatiky Masarykovej univerzity v Brne
              </div>
              <div class="cv__description">
                Štúdium úspešne zakončené s titulom Bc.
              </div>
            </div>
            <div class="cv__item">
              <div class="cv__head">
                <div class="cv__title">Aplikovaná informatika Mgr.</div>
                <div class="cv__date">09/2021 – <strong>súčasnosť</strong></div>
              </div>
              <div class="cv__institution">
                Prírodovedecká fakulta Univerzity Palackého v Olomouci
              </div>
              <div class="cv__description">
                Nadväzujúce magisterské štúdium so špecializáciou Vývoj
                software.
              </div>
            </div>
          </div>
          <div class="cv__columns">
            <div class="cv__column cv__column_grow">
              <div class="cv__heading">
                <span class="cv__logo">»</span>Jazyky
              </div>
              <div class="cv__content">
                <div class="cv__langs">
                  <div class="cv__lang">
                    <div class="cv__flag cv__flag_gb"></div>
                    C1
                  </div>
                  <div class="cv__lang">
                    <div class="cv__flag cv__flag_ru"></div>
                    B1
                  </div>
                  <div class="cv__lang">
                    <div class="cv__flag cv__flag_de"></div>
                    A1
                  </div>
                </div>
              </div>
            </div>
            <div class="cv_column">
              <div class="cv__download">
                <a class="cv__button" href="StanislavCingel.pdf" download>
                  <div class="cv__button-icon">
                    <img src="../assets/file-pdf.svg" />
                  </div>
                  <div class="cv__button-label">
                    Stiahnuť životopis<br />vo formáte PDF
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  props: {
    msg: String
  },
  methods: {
    scrollTo(selector) {
      var element = document.querySelector(selector);
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>

<style lang="scss">
.cv {
  @include above($c-secondary);

  border-radius: 0;
  display: flex;
  justify-content: center;

  &__center {
    padding: 5rem 3rem 0;
    color: $c-white;
    max-width: 1920px;

    @media (max-width: 900px) {
      padding: 2rem;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2rem;
  }

  &__category {
    flex-shrink: 0;
    flex-basis: 50%;
    margin-bottom: 4rem;
    padding: 0 4rem;
    display: flex;
    flex-direction: column;

    &_compact {
      flex-basis: 33.33%;
    }

    @media (max-width: 1100px) {
      flex-basis: 100%;
      padding: 0 2rem;
      margin-bottom: 2rem;
    }
  }

  &__heading {
    display: inline-block;
    font-weight: 700;
    width: fit-content;
    // color: $c-primary;
    font-size: $font-28px;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba($c-white, 0.25);
  }

  &__logo {
    font-size: 36px;
    margin-right: 0.5rem;
  }

  &__content {
    line-height: 1.5em;
    font-size: $font-18px;
  }

  &__content + &__heading {
    margin-top: 4rem;
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 3.5rem;

      @media (max-width: 900px) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__head {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  &__date {
    opacity: 0.75;
    float: right;
    border-radius: 100px;
  }

  &__title {
    font-weight: 700;
    // color: $c-secondary;
    font-size: $font-26px;
    margin-right: 1rem;
    flex-grow: 1;

    @media (max-width: 900px) {
      margin-bottom: 1rem;
    }
  }

  &__institution {
    font-weight: 600;
    // color: $c-secondary;
    font-size: 18px;
    margin-bottom: 1rem;
  }

  &__description {
    font-size: $font-18px;
    line-height: 1.5em;

    a {
      color: inherit;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__langs {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__lang {
    @include above(rgba($c-white, 0.15));

    display: flex;
    align-items: center;
    // border-radius: 100px;
    font-weight: 600;
    font-size: $font-20px;
    padding: 0.5rem;
    padding-right: 0.75rem;
    color: $c-white;

    @media (max-width: 1100px) {
      font-size: $font-18px;
    }
  }

  &__flag {
    // @include above;

    // border: 2px solid $c-gray;
    height: 2.25rem;
    width: 2.25rem;
    background-size: cover;
    background-position: center;
    margin-right: 0.75rem;
    border-radius: 100px;

    @media (max-width: 1100px) {
      height: 1.75rem;
      width: 1.75rem;
      margin-right: 0.65rem;
    }

    &_gb {
      background-image: url(../assets/gb.svg);
    }

    &_ru {
      background-image: url(../assets/ru.svg);
    }

    &_de {
      background-image: url(../assets/de.svg);
    }
  }

  &__download {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 100%;
    flex-grow: 1;
  }

  &__button {
    @include above($c-green);

    width: fit-content;
    // border: 1px solid $c-font-black;
    padding: 1.25rem 1.75rem;
    padding-left: 1.25rem;
    font-weight: 600;
    font-size: $font-20px;
    cursor: pointer;
    // color: $c-font-black;
    color: $c-white;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.35em;
    text-decoration: none;
    box-shadow: 0 0 1rem -0.25rem rgba($c-black, 0.25);
  }

  &__button-icon {
    font-size: 1.5em;
    font-weight: 800;
    // border-right: 1px solid rgba($c-black, .25);
    // margin-right: 1.5rem;
    margin-right: 0.75rem;
    // color: $c-primary;
    width: 50px;

    img {
      aspect-ratio: 1/1;
    }
  }

  &__button-label {
    .cv__button:hover & {
      text-decoration: underline;
    }
  }

  &__columns {
    display: flex;
    gap: 2rem;
    align-items: flex-end;
    margin-top: 4rem;

    @media (max-width: 1400px) {
      flex-direction: column;
      align-items: normal;
      justify-content: flex-start;
    }
  }

  &__column {
    flex-basis: 50%;

    &_grow {
      flex-grow: 1;
    }

    &:not(:last-child) {
      @media (max-width: 1100px) {
        margin-bottom: 4rem;
      }
    }
  }
}
</style>
