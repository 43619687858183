<template>
    <div class="footer">© 2023 Stanislav Cingel</div>
</template>

<script>
export default {
  name: 'Footer',
  props: {}
}
</script>

<style lang="scss">
    .footer {
        padding: 1.5rem;
        text-align: center;
        background-color: $c-tertiary;
        font-size: $font-18px;
        color: $c-white;
        position: relative;
        z-index: 20;
    }
</style>