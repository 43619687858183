<template>
  <div class="projects">
    <div class="projects__container">
      <div class="projects__item projects__item_najdispoj">
        <div class="projects__wrapper">
          <div class="projects__details">
            <div class="projects__logo">
              <img class="projects__img" src="../assets/najdispoj_logo.svg" />
            </div>
            <div class="projects__techs">
              <div class="projects__tech">Docker</div>
              <div class="projects__tech">Nginx</div>
              <div class="projects__tech">Python</div>
              <div class="projects__tech">FastAPI</div>
              <div class="projects__tech">Vue.js</div>
              <div class="projects__tech">Sass</div>
              <div class="projects__tech">Leaflet</div>
              <div class="projects__tech">GTFS</div>
            </div>
            <div class="projects__description">
              <p style="font-style: italic">
                Open source projekt, umožňujúci jednoducho a bezplatne vytvoriť
                a nasadiť do prevádzky moderný vyhľadávač dopravných spojov.
              </p>
              <p>
                <i>Najdispoj</i> je platforma založená na Dockeri, umožňujúca
                jednoducho vytvoriť a spravovať svoj vlastný vyhľadávač
                dopravných spojov. Vyhľadávač umožňuje hľadať spoj nielen zo
                zastávky na zastávku –⁠
                <b>štartom a cieľom môže byť ľubovoľné miesto na mape</b>.
              </p>
              <p><i>Najdispoj</i> kladie dôraz na:</p>
              <ul class="projects__list">
                <li>
                  <span class="bullet" style="color:#f50101">⬤</span> zlepšenie
                  prístupnosti a zvýšenie atraktivity verejnej dopravy
                </li>
                <li>
                  <span class="bullet" style="color:#eeb600">⬤</span>
                  prispôsobiteľnosť, rozšíriteľnosť a možnosť integrácie do
                  ďalších systémov
                </li>
                <li>
                  <span class="bullet" style="color:#077fcf">⬤</span>
                  presadzovanie použitia open source softvéru a otvorených dát
                  vo verejnej sfére
                </li>
              </ul>
              <p>
                Projektu som sa venoval vo svojej
                <a href="https://is.muni.cz/th/cpbbd/" target="_blank"
                  >bakalárskej práci</a
                >
                a momentálne na ňom pracujem v rámci diplomovej práce.
                Problematikou hromadnej dopravy sa okrajovo zaoberám od roku
                2016.
              </p>
              <p>Vyhľadávač v súčasnosti disponuje následujúcimi dátami:</p>
              <ul>
                <li>Dopravný podnik Bratislava, a.s. (+ polohy vozidiel)</li>
                <li>Arriva Mobility Solutions, s.r.o.</li>
                <li>Železničná spoločnosť Slovensko, a.s.</li>
                <li>Leo Express Global a.s.</li>
                <li>Dopravní podnik města Olomouce, a.s.</li>
                <li>celá oblasť IDS JMK (Juhomoravský kraj)</li>
              </ul>
              <a
                href="https://www.alvaria.sk/najdispoj-sk-uzitocny-vyhladavac-spojov-verejnej-dopravy/"
                target="_blank"
                >Najdispoj.sk – užitočný vyhľadávač spojov verejnej dopravy
                založený na otvorených dátach (Alvaria.sk)</a
              >
            </div>
            <div class="projects__buttons">
              <a
                class="projects__button"
                href="https://najdispoj.sk"
                target="_blank"
                >Navštíviť »</a
              >
              <a
                class="projects__button projects__button_alt"
                href="https://gitlab.com/cstanislav/najdispoj"
                target="_blank"
                >Zdrojový kód</a
              >
            </div>
          </div>
          <div class="projects__screenshot">
            <img class="projects__screenimg" src="../assets/najdispoj.webp" />
            <img class="projects__screenimg" src="../assets/najdispoj2.webp" />
          </div>
        </div>
      </div>

      <div class="projects__item projects__item_adbaits">
        <div class="projects__wrapper">
          <div class="projects__details">
            <div class="projects__logo">
              <img class="projects__img" src="../assets/adbaits_logo.png" />
            </div>
            <div class="projects__techs">
              <div class="projects__tech">WordPress</div>
              <div class="projects__tech">WooCommerce</div>
              <div class="projects__tech">PHP</div>
              <div class="projects__tech">Twig</div>
              <div class="projects__tech">Sass</div>
              <div class="projects__tech">jQuery</div>
            </div>
            <div class="projects__description">
              <p style="font-style: italic">
                E-shop slovenského výrobcu rybárskych nástrah a návnad.
              </p>
              <p>
                Pozostáva z CMS WordPress, pluginu WooCommerce a témy vytvorenej
                na mieru s pomocou PHP a šablónového systému Twig.
              </p>
            </div>
            <div class="projects__buttons">
              <a
                class="projects__button"
                href="https://adbaits.sk"
                target="_blank"
                >Navštíviť »</a
              >
            </div>
          </div>
          <div class="projects__screenshot">
            <img class="projects__screenimg" src="../assets/adbaits.webp" />
          </div>
        </div>
      </div>
      <div class="projects__inqool">
        <div class="projects__inqool-wrapper">
          <div class="projects__text">
            Počas mojich 3 rokov strávených vo firme
            <strong>inQool a.s.</strong> som sa v rôznej miere podieľal na
            vývoji (okrem iného) nasledujúcich projektov:
          </div>
          <div class="projects__grid">
            <a
              class="projects__compact projects__compact_crr"
              href="https://crr.cz"
              target="_blank"
              >crr.cz</a
            >
            <a
              class="projects__compact projects__compact_tacr"
              href="https://tacr.cz"
              target="_blank"
              >tacr.cz</a
            >
            <a
              class="projects__compact projects__compact_grantys"
              href="https://grantys.cz"
              target="_blank"
              >grantys.cz</a
            >
            <a
              class="projects__compact projects__compact_trencin"
              href="https://trencin.sk"
              target="_blank"
              >trencin.sk</a
            >
            <a
              class="projects__compact projects__compact_prazskyvoucher"
              href="https://prazskyvoucher.cz"
              target="_blank"
              >prazskyvoucher.cz</a
            >
            <a
              class="projects__compact projects__compact_mestouvaly"
              href="https://mestouvaly.cz"
              target="_blank"
              >mestouvaly.cz</a
            >
            <a
              class="projects__compact projects__compact_pvpbukov"
              href="https://pvpbukov.cz"
              target="_blank"
              >pvpbukov.cz</a
            >
            <a
              class="projects__compact projects__compact_nsz"
              href="https://verejnazaloba.cz"
              target="_blank"
              >verejnazaloba.cz</a
            >
            <a
              class="projects__compact projects__compact_socharske"
              href="https://socharske.brno.cz"
              target="_blank"
              >socharske.brno.cz</a
            >
            <a
              class="projects__compact projects__compact_brno2050"
              href="https://brno2050.cz"
              target="_blank"
              >brno2050.cz</a
            >
            <a
              class="projects__compact projects__compact_darujmekrev"
              href="https://darujmekrev.brno.cz"
              target="_blank"
              >darujmekrev.brno.cz</a
            >
            <a
              class="projects__compact projects__compact_plugandplay"
              href="https://plugandplay.brno.cz"
              target="_blank"
              >plugandplay.brno.cz</a
            >
            <a
              class="projects__compact projects__compact_probudejce"
              href="https://probudejce.cz"
              target="_blank"
              >probudejce.cz</a
            >
            <a
              class="projects__compact projects__compact_mujkraj"
              href="https://mujkraj.kr-stredocesky.cz"
              target="_blank"
              >mujkraj.kr-stredocesky.cz</a
            >
            <a
              class="projects__compact projects__compact_sfzp"
              href="https://sfzp.cz"
              target="_blank"
              >sfzp.cz</a
            >
            <a
              class="projects__compact projects__compact_praha7"
              href="https://praha7.cz"
              target="_blank"
              >praha7.cz</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Projects",
  props: {}
};
</script>

<style lang="scss">
.projects {
  $max-width: 1500px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    border-bottom: 1px solid rgba($c-black, 0.05);
    // margin-bottom: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;

    &:nth-child(2n) {
      .projects__wrapper {
        flex-direction: row-reverse;
      }
    }
  }

  &__wrapper {
    display: flex;
    max-width: $max-width;
    padding: 5rem;
    gap: 4rem;

    @media (max-width: 900px) {
      flex-direction: column !important;
      padding: 2rem;
    }
  }

  &__details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: 900px) {
      margin-bottom: 2rem;
    }
  }

  &__logo {
    width: 300px;
    margin-bottom: 1.5rem;

    .projects__item_adbaits & {
      width: 100px;
    }
  }

  &__img {
    width: 100%;
  }

  &__techs {
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;

    &_center {
      justify-content: center;
    }
  }

  &__tech {
    @include above;
    // margin-bottom: .65rem;
    // font-size: $font-20px;
    font-weight: 600;
    // background-color: dar ken($c-white, 7);
    padding: 0.45rem 0.7rem;
  }

  &__screenshot {
    flex-shrink: 0;
    flex-basis: 550px;
    height: fit-content;

    @media (max-width: 1100px) {
      flex-basis: 300px;
    }

    @media (max-width: 900px) {
      flex-basis: auto;
    }
  }

  &__screenimg {
    @include above;

    width: 100%;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  &__info {
    padding: 2rem;
    flex-basis: 50%;
  }

  &__address {
    font-weight: 700;
    font-size: $font-26px;
    margin-bottom: 1.5rem;
    display: inline-block;
  }

  &__description {
    font-size: $font-20px;
    line-height: 1.5em;
    // flex-grow: 1;
    margin-bottom: 1.5rem;

    @media (max-width: 900px) {
      font-size: $font-18px;
    }

    p:first-child {
      margin-top: 0;
    }

    ul.projects__list {
      padding-left: 0;

      li {
        list-style-type: none;
        display: flex;

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        .bullet {
          font-size: 1.35rem;
          margin-right: 0.75rem;
        }
      }
    }
  }

  &__buttons {
    display: flex;
  }

  &__button {
    @include a-unstyled;

    padding: 0.85rem 1.25rem;
    font-size: $font-20px;
    font-weight: 600;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 900px) {
      font-size: $font-18px;
      padding: 0.65rem 1rem;
      text-align: center;
    }

    .projects__item_najdispoj &:not(&_alt) {
      @include above($c-najdispoj);
      color: $c-white;
    }

    .projects__item_adbaits &:not(&_alt) {
      @include above($c-adbaits);
      color: $c-white;
    }

    &_alt {
      @include above;
    }
  }

  &__inqool {
    width: 100%;
    // background-color: $c-gray;
    display: flex;
    justify-content: center;
  }

  &__inqool-wrapper {
    max-width: $max-width;
    padding: 5rem;

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column !important;
      padding: 2rem;
    }
  }

  &__text {
    margin-bottom: 2.5rem;
    font-size: $font-20px;
    line-height: 1.5em;
  }

  &__grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    // margin: 0 -.5rem;
    // grid-template-columns: repeat(4, minmax(0, 1fr));
    // grid-gap: 1rem;
    color: $c-white;
  }

  &__compact {
    @include a-unstyled;

    flex-grow: 1;
    text-decoration: none;
    font-weight: 600;
    font-size: $font-20px;
    text-align: center;
    padding: 2rem;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 1100px) {
      padding: 1rem;
      font-size: $font-16px;
    }

    &_trencin {
      &,
      &:hover {
        @include above(#009ee3);
      }
    }

    &_tacr {
      &,
      &:hover {
        @include above(#f03741);
      }
    }

    &_brno2050 {
      &,
      &:hover {
        @include above;
        color: #1d1d1b;
        font-weight: 700;
      }
    }

    &_darujmekrev {
      &,
      &:hover {
        @include above;
        // border: 1px solid rgba($c-black, .15);
        color: #df2726;
      }
    }

    &_socharske {
      &,
      &:hover {
        @include above;
        // border: 1px solid rgba($c-black, .15);
        color: #1d1d1b;
        font-weight: 300;
      }
    }

    &_plugandplay {
      &,
      &:hover {
        @include above;
        // border: 1px solid rgba($c-black, .15);
        color: #df2726;
      }
    }

    &_grantys {
      &,
      &:hover {
        @include above;
        // border: 1px solid rgba($c-black, .15);
        color: #0094d2;
      }
    }

    &_prazskyvoucher {
      @include above(#c81428);
    }

    &_nacr {
      &,
      &:hover {
        @include above;
        // border: 1px solid rgba($c-black, .15);
        color: #004b93;
        font-weight: 700;
      }
    }

    &_nsz {
      @include above(#c30043);
    }

    &_probudejce {
      &,
      &:hover {
        @include above;
        // border: 1px solid rgba($c-black, .15);
        color: #1dbcff;
      }
    }

    &_mujkraj {
      @include above(#e31f27);
    }

    &_praha7 {
      &,
      &:hover {
        @include above;
        // border: 1px solid rgba($c-black, .15);
        color: #1b4e9b;
      }
    }

    &_mestouvaly {
      @include above(#7bbb57);
    }

    &_sfzp {
      @include above(#2da343);
    }

    &_pvpbukov {
      &,
      &:hover {
        @include above(#dbe5dd);
        color: $c-font-black;
      }
    }

    &_crr {
      @include above(#014a94);
    }
  }

  &__other {
    width: 100%;
    max-width: $max-width;
  }
}
</style>
