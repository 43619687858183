<template>
    <div class="domain">
        <div class="domain__container">
            <div class="domain__header">Ste tiež Cingel/ová?</div>
            <div class="domain__content">
                <p>Každý Cingel má nárok <b>bezplatne</b> používať emailovú adresu a doménu v tvare <b>vašemeno@cingel.sk</b>, resp. <b>vašemeno.cingel.sk</b>.</p>
                <p>Napíšte mi na <a href="mailto:stanislav@cingel.sk">stanislav@cingel.sk</a> a adresu/subdoménu pre Vás zriadim.</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Domain',
    props: {},
    methods: {
    }
}

</script>
<style lang="scss">
.domain {
    $max-width: 1500px;
    border-top: 1px solid rgba($c-black, .05);

    // background-color: $c-primary;
    color: $c-primary;
    display: flex;
    justify-content: center;

    &__container {
        max-width: $max-width;
        width: $max-width;
        margin: 0 auto;
        padding: 4rem 5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @media (max-width: $breakpoint-mobile) {
            padding: 2rem;
        }
    }

    &__header {
        font-size: 2rem;
        font-weight: 700;
    }

    &__content {
        font-size: 1.35rem;
        font-weight: 400;

        a {
            font-weight: 700;
            color: $c-primary;
        }

        p {
            margin: 0;
            line-height: 1.35em;
        }

        p+p {
            margin-top: .75rem;
        }
    }
}
</style>