<template>
  <div class="page">
    <div class="main">
      <div class="intro">
        <div class="intro__center">
          <div class="intro__main">
            <h1 class="intro__heading">
              <span class="intro__logo">»</span>Dobrý deň, moje meno je
              <span class="intro__name">Stanislav Cingel</span>.
            </h1>
            <div class="intro__text">
              <p>
                Som absolventom bakalárskeho študijného programu Aplikovaná
                informatika na
                <strong>Fakulte Informatiky Masarykovej univerzity</strong> v
                Brne. V&nbsp;štúdiu Aplikovanej informatiky na magisterskom
                stupni pokračujem na
                <strong>Univerzite Palackého v&nbsp;Olomouci</strong>. Popri
                štúdiu sa venujem <strong>tvorbe webových aplikácií</strong>.
              </p>
              <p>
                V&nbsp;súčasnosti pracujem pre spoločnosť
                <strong
                  >AT&T Global Network Services Czech Republic s.r.o.</strong
                >
              </p>
            </div>
            <div class="intro__navigation">
              <div class="intro__buttons">
                <div class="intro__button" @click="scrollTo('.cv')">
                  Životopis
                </div>
                <div class="intro__button" @click="scrollTo('.projects')">
                  Projekty
                </div>
                <div class="intro__button" @click="scrollTo('.contact')">
                  Kontakt
                </div>
              </div>
              <div class="intro__socials">
                <a
                  class="intro__social"
                  href="https://gitlab.com/cstanislav"
                  target="_blank"
                >
                  <img class="intro__icon" src="../assets/git-alt.svg" alt="" />
                </a>
                <a
                  class="intro__social"
                  href="https://www.linkedin.com/in/stanislavcingel"
                  target="_blank"
                >
                  <img
                    class="intro__icon"
                    src="../assets/linkedin.svg"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="intro__side">
            <div class="intro__bg">
              <img class="intro__photo" src="../assets/portrait_lowres.webp" />
            </div>
          </div>
        </div>
      </div>
      <CV />
      <Projects />
      <Domain />
      <Contact />
      <Footer />
    </div>
  </div>
</template>

<script>
import CV from "./CV.vue";
import Projects from "./Projects.vue";
import Contact from "./Contact.vue";
import Footer from "./Footer.vue";
import Domain from "./Domain.vue";

export default {
  name: "Main",
  props: {},
  components: {
    CV,
    Projects,
    Contact,
    Footer,
    Domain
  },
  methods: {
    scrollTo(selector) {
      var element = document.querySelector(selector);
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>

<style lang="scss">
.page {
  color: $c-font-black;
}

.main {
  max-width: 100%;
  margin: 0 auto;
}

.intro {
  &__center {
    display: flex;
    // border-top: 1rem solid $c-primary;
    max-width: 1920px;
    margin: 0 auto;

    @media (max-width: 1000px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__main {
    flex-basis: 65%;
    flex-shrink: 0;
    padding: 5rem;

    @media (max-width: 900px) {
      padding: 2rem;
    }

    @media (max-width: 1000px) {
      flex-basis: 100%;
    }

    strong {
      font-weight: 700;
      color: $c-secondary;
    }
  }

  &__heading {
    font-size: $font-32px;

    @media (max-width: 900px) {
      font-size: $font-26px;
    }
  }

  &__logo {
    color: $c-primary;
    font-size: 42px;
    margin-right: 0.5rem;

    @media (max-width: 900px) {
      font-size: $font-28px;
    }
  }

  &__name {
    font-weight: 700;
    color: $c-primary;
  }

  &__text {
    margin-top: 1em;
    margin-bottom: 1.5em;
    font-size: $font-26px;
    line-height: 1.5em;
    text-align: justify;

    @media (max-width: 900px) {
      font-size: $font-18px;
      text-align: left;
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    @media (max-width: 1100px) {
      flex-direction: column;
    }
  }

  &__buttons {
    display: flex;
    gap: 1rem;

    @media (max-width: 1000px) {
      justify-content: center;
    }
  }

  &__button {
    @include above($c-yellow);

    // color: $c-white;

    padding: 0.85rem 1.25rem;
    font-weight: 600;
    font-size: $font-22px;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 900px) {
      font-size: $font-18px;
      text-align: center;
    }

    @media (max-width: 600px) {
      flex-grow: 1;
      padding: 0.65rem 0.5rem;
    }
  }

  &__socials {
    display: flex;
    gap: 1rem;
  }

  &__social {
    display: block;
  }

  &__icon {
    height: 50px;
  }

  &__side {
    flex-grow: 1;
    display: block;
    padding-bottom: 5rem;

    @media (max-width: 1000px) {
      max-width: 500px;
      padding: 0 2rem;
      display: flex;
      justify-content: center;
    }
  }

  &__bg {
    background-color: rgba($c-black, 0.05);
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;
    position: relative;
    width: 65%;
    min-width: 350px;
    overflow: hidden;

    @media (min-width: 1400px) {
      height: 100%;
    }

    @media (max-width: 1000px) {
      min-width: auto;
      width: 70%;
    }
  }

  &__photo {
    margin-top: 4rem;
    width: 100%;
    max-width: 100%;

    @media (min-width: 1400px) {
      max-height: calc(100% - 4rem);
      position: absolute;
      bottom: 0;
      object-fit: contain;
    }
  }
}
</style>
